document.addEventListener('DOMContentLoaded', () => {

  const yaTargets = [
    'header_phone',
    'header_btn',
    'request_standart',
    'request_buisnes',
    'request_vip',
    'main_audit',
    'main_seo',
    'main_smm',
    'main_direct',
    'main_brif',
    'contacts_phone',
    'contacts_mail',
    'contacts_wats',
    'contacts_vk',
    'contacts_mail',
    'contacts_viber',
    'footer_form',
    'popup_form',
    'hero_seo',
    'hero_context',
    'hero_create',
    'hero_builders',
    'hero_denistry',
    'hero_fitness',
    'hero_restaurant',
    'hero_shop',
    'hero_car-buisnes',
    'hero_corporation',
    'hero_cyber-security',
    'hero_incedent-managment',
    'hero_app-security',
    'hero_hero-protection',
    'hero_stress-testing',
    'hero_penetration-testing',
    'hero_grade',
    'hero_intelligence',
    'hero_soprovozhdenie',
    'hero_smm',
    'hero_target',
    'hero_audit',
    'hero_tilda',
  ];

  yaTargets.forEach((el) => {
    if (document.getElementById(el)) {
      let target = document.getElementById(el);
      target.addEventListener('click', () => {
        ym(92594057, 'reachGoal', el);
      })
    } else {
      return
    }
  })
})


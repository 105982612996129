const flipBtns = document.querySelectorAll('[data-flip]');

flipBtns.forEach((el) => {

  if (innerWidth < 1024) {
    el.addEventListener('click', () => {
      const parent = el.closest(".cases__card");
      parent.querySelector('.cases__card-front').classList.toggle('active');
      parent.querySelector('.cases__card-back').classList.toggle('active');
    });
  }
})

import Inputmask from "inputmask";


const footerForm = new Inputmask('+7 (999) 999-99-99');
const popupForm = new Inputmask('+7 (999) 999-99-99');

footerForm.mask('.form__input-phone--foo');
popupForm.mask('.form__input-phone--pop');



import Splide from '@splidejs/splide';

document.addEventListener("DOMContentLoaded", () => {

if (document.querySelector('.partners-cyber__splide')) {

  let partnersSlider = new Splide('.partners-cyber__splide', {
    type: 'loop',
    autoplay: true,
    interval: 0,
    speed: '25000',
    easing: 'linear',
    autoWidth: true,
    arrows: false,
    direction: 'ltr',
    gap: '180px',
    pagination: false,
    breakpoints: {
      1024: {
        gap: '160px',
      },
      930: {
        gap: '150px',
      },
      768: {
        gap: '140px',
      },
      576: {
        gap: '70px',
      },
      480: {
        gap: '60px',
      },
      400: {
        gap: '50px',
      },
      350: {
        gap: '45px',
      },
    },
  }).mount();

}
})

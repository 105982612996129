document.addEventListener("DOMContentLoaded", function () {
  let gototop = document.getElementById('gototop');
  let body = document.documentElement;
  const fire = document.querySelector('.gototop-fire__img');

  window.addEventListener("scroll", check);

  function check() {
    scrollY >= 500 && gototop.classList.add("gototop--visible");
    scrollY < 500 && gototop.classList.remove("gototop--visible");
    scrollY < 500 && fire.classList.remove("gototop-fire__img--visible");
  }

  gototop.onclick = function () {
    fire.classList.add('gototop-fire__img--visible')
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  };

});

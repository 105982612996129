// const $footer = document.querySelector('footer');
// let countFooter = 0;

// function setPosFooter(el) {
//   countFooter += 0.5;
//   el.style.backgroundPosition = `${countFooter}px top`;
// };

// setInterval(() => {setPosFooter($footer)}, 50);




// const anchors = document.querySelectorAll('a[href*="/#"]');

// for (let anchor of anchors) {
//   anchor.addEventListener('click', (ev) => {
//     ev.preventDefault();
//     const regExp = /[#]/g;
//     const blockID = anchor.getAttribute('href').replace(regExp, '');
// console.log(blockID);
//     document.getElementById(`${blockID.replace(regExp, '')}`).scrollIntoView({
//       behavior: 'smooth',
//       block: "start",
//     })
//   })
// }

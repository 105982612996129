const $modalsBtns = document.querySelectorAll('[data-modal]');
const $popup = document.getElementById('popup');
const $popupOverlay = document.getElementById('popup-overlay');
const $popupWrap = document.getElementById('popup-wrap');
const $requestModal = document.getElementById('request-modal');
const $requestModalTitle = document.getElementById('request-modal-title');
const $closePopup = document.getElementById('popup-close');
const $serviceTitle = document.getElementById('request-modal-service')
let countSpace = 0;
let intervalPopupBg;

function setPosSpace(el) {
  countSpace -= 0.5;
  el.style.backgroundPosition = `${countSpace}px`;
};

function checkInterval(flag) {
  if (flag) {
    intervalPopupBg = setInterval(() => {setPosSpace($popupOverlay)}, 60);
  }
  if (!flag) {
  clearInterval(intervalPopupBg);
  }
}

$modalsBtns.forEach((el) => {
  el.addEventListener('click', (ev) => {
    const dataAttrValue = el.getAttribute('data-modal');
    $serviceTitle.innerHTML = dataAttrValue;
    document.querySelector('body').style.overflowY = 'hidden';
    checkInterval(true);
    $popupOverlay.classList.add('popup__overlay--visible');
    $requestModal.classList.add('popup__modal--visible');
  });
});

$popupOverlay.addEventListener('click', (ev) => {
  if (ev.target === $popupWrap) {
    closePopup();
  }
})

$closePopup.addEventListener('click', () => {
  closePopup();
})

function closePopup() {
  document.querySelector('body').style.overflowY = 'visible';
  $popupOverlay.classList.remove('popup__overlay--visible');
  $requestModal.classList.remove('popup__modal--visible');
  checkInterval(false);
  $requestModalTitle.innerHTML = 'Оставить заявку';
}

// Данный файл - лишь собрание подключений готовых компонентов.
// Рекомендуется создавать отдельный файл в папке components и подключать все там

// Определение операционной системы на мобильных
import { mobileCheck } from "./functions/mobile-check";
console.log(mobileCheck())

// Определение ширины экрана
// import { isMobile, isTablet, isDesktop } from './functions/check-viewport';
// if (isDesktop()) {
//   console.log('...')
// }

// Троттлинг функции (для ресайза, ввода в инпут, скролла и т.д.)
// import { throttle } from './functions/throttle';
// let yourFunc = () => { console.log('throttle') };
// let func = throttle(yourFunc);
// window.addEventListener('resize', func);

// Фикс фулскрин-блоков
// import './functions/fix-fullheight';

// Реализация бургер-меню
// import { burger } from './functions/burger';

// Реализация остановки скролла (не забудьте вызвать функцию)
// import { disableScroll } from './functions/disable-scroll';

// Реализация включения скролла (не забудьте вызвать функцию)
// import { enableScroll } from './functions/enable-scroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();

// Реализация табов
// import GraphTabs from 'graph-tabs';
// const tabs = new GraphTabs('tab');

// Получение высоты шапки сайта (не забудьте вызвать функцию)
// import { getHeaderHeight } from './functions/header-height';

// Подключение плагина кастом-скролла
// import 'simplebar';

// Подключение плагина для позиционирования тултипов
// import { createPopper, right} from '@popperjs/core';
// createPopper(el, tooltip, {
//   placement: 'right'
// });

// Подключение свайпера
// import Swiper, { Navigation, Pagination } from 'swiper';
// Swiper.use([Navigation, Pagination]);
// const swiper = new Swiper(el, {
//   slidesPerView: 'auto',
// });

// Подключение анимаций по скроллу
// import AOS from 'aos';
// AOS.init();

// Подключение параллакса блоков при скролле
// import Rellax from 'rellax';
// const rellax = new Rellax('.rellax');

// Подключение плавной прокрутки к якорям
// import SmoothScroll from 'smooth-scroll';
// const scroll = new SmoothScroll('a[href*="#"]');

// Подключение событий свайпа на мобильных
// import 'swiped-events';
// document.addEventListener('swiped', function(e) {
//   console.log(e.target);
//   console.log(e.detail);
//   console.log(e.detail.dir);
// });

// import { validateForms } from './functions/validate-forms';

// const rulesFoo = [
//   {
//     ruleSelector: '.form__input-name--foo',
//     rules: [
//       {
//         rule: 'minLength',
//         value: 2,
//         errorMessage: 'Самое короткое имя состоит из 2 букв!'
//       },
//       {
//         rule: 'required',
//         value: true,
//         errorMessage: 'Заполните имя!'
//       },
//     ]
//   },
//   {
//     ruleSelector: '.form__input-phone--foo',
//     tel: true,
//     telError: 'Введите корректный телефон',
//     rules: [
//       {
//         rule: 'required',
//         value: true,
//         errorMessage: 'Заполните телефон!',
//       }
//     ]
//   },
//   {
//     ruleSelector: '.form__input-check--foo',
//     rules: [
//       {
//         rule: 'required',
//         errorMessage: 'Обязательное поле!'
//       }
//     ]
//   },
// ];

// const rulesPop = [
//   {
//     ruleSelector: '.form__input-name--pop',
//     rules: [
//       {
//         rule: 'minLength',
//         value: 2,
//         errorMessage: 'Самое короткое имя состоит из 2 букв!'
//       },
//       {
//         rule: 'required',
//         value: true,
//         errorMessage: 'Заполните имя!'
//       },
//     ]
//   },
//   {
//     ruleSelector: '.form__input-phone--pop',
//     tel: true,
//     telError: 'Введите корректный телефон',
//     rules: [
//       {
//         rule: 'required',
//         value: true,
//         errorMessage: 'Заполните телефон!',
//       }
//     ]
//   },
//   {
//     ruleSelector: '.form__input-check--pop',
//     rules: [
//       {
//         rule: 'required',
//         errorMessage: 'Обязательное поле!'
//       }
//     ]
//   },
// ];

// const popupForm = document.getElementById('popup-form-val');
// const fooForm = document.getElementById('foo-form');

// const afterForm = () => {
//   console.log('Произошла отправка, тут можно писать любые действия');

//   if (document.getElementById('popup-close')) {
//     closePopup = document?.getElementById('popup-close');
//     closePopup.click();
//   }
// };

// if (popupForm) {
//   validateForms('.popup__form', rulesPop, afterForm);
// }

// if (fooForm) {
//   validateForms('.form__form', rulesFoo, afterForm);
// }


const burger = document.getElementById('burger');
const overlay = document.getElementById('overlay');
const saturn = document.getElementById('saturn');
const headerModal = document.getElementById('header-modal');
const headerModalClose = document.getElementById('header-popup-close');
const $saturn = document.getElementById('saturn');
let countSaturn = 0;
let intervalsaturn;

function setPosSaturn(el) {
  innerWidth > 1024 ? countSaturn += 0.5 : countSaturn += 1;
  el.style.backgroundPosition = `${-countSaturn}px top`;
};

function checkIntervalSaturn(flag) {
  if (flag) {
    intervalsaturn = setInterval(() => {setPosSaturn($saturn)}, 60);
  }
  if (!flag) {
  clearInterval(intervalsaturn);
  }
}

burger.addEventListener('click', () => {
  overlay.classList.add('active');
  document.querySelector('body').style.overflowY = 'hidden';
  checkIntervalSaturn(true);
});

headerModalClose.addEventListener('click', () => {
  overlay.classList.remove('active');
  document.querySelector('body').style.overflowY = 'visible';
  checkIntervalSaturn(false);
})

document.addEventListener('click', (e) => {
  if (e.target === overlay || e.target === saturn) {
    overlay.classList.remove('active');
    document.querySelector('body').style.overflowY = 'visible';
    checkIntervalSaturn(false);
  }
});

const accordions = document.querySelectorAll(".accordion");

const openAccordion = (accordion) => {
  const content = accordion.querySelector(".accordion__content");
  const icon = accordion.querySelector('.accordion__icon');
  accordion.classList.add("accordion__active");
  icon.classList.add('icon-active');
  // content.style.maxHeight = content.scrollHeight + "px";
};

const closeAccordion = (accordion) => {
  const content = accordion.querySelector(".accordion__content");
  const icon = accordion.querySelector('.accordion__icon');
  accordion.classList.remove("accordion__active");
  icon.classList.remove('icon-active');
  // content.style.maxHeight = null;
};

accordions.forEach((accordion) => {
  const intro = accordion.querySelector(".accordion__intro");
  const content = accordion.querySelector(".accordion__content");

  intro.onclick = () => {
    if (accordion.classList.contains('accordion__active')) {
      closeAccordion(accordion);
    } else {
      accordions.forEach((accordion) => closeAccordion(accordion));
      openAccordion(accordion);
    }
  };
});

import jQuery from "../../../node_modules/jquery";

jQuery(document).ready(function ($) {
  $(".popup__form .popup__form-btn").click(function () {
    if ($('.form__input-name--pop').val() !== '') {
      if ($('.form__input-phone--pop').val() !== '') {
        $.ajax({
          type: "POST",
          url: '/sender.php',
          data: {
            title: $('.popup__service-title').text(),
            imya: $('.form__input-name--pop').val(),
            tel: $('.form__input-phone--pop').val()
          },
          success: function () {
            thiser.after('<div class="uspeh1">Ваше сообщение отправлено.<br>Наш специалист свяжется с Вами в ближайшее время.</div>');
            // ym(92594057, 'reachGoal', 'zayvka');
            setTimeout(function () {
              $(".uspeh1").detach();
              $('.popup__form').val('');
            }, 3000);
          }
        });
      }
      else {
        thiser.after('<div class="uspeh2">Поле "Ваш телефон" не заполнено.</div>');
        setTimeout(function () {
          $(".uspeh2").detach();
        }, 3000);
      }
    }
    else {
      thiser.after('<div class="uspeh2">Заполните Ваше имя.</div>');
      setTimeout(function () {
        $(".uspeh2").detach();
      }, 3000);
    }
  });

  $(".form__form .form__btn").click(function () {
    if ($('.form__input-name--foo').val() !== '') {
      if ($('.form__input-phone--foo').val() !== '') {
        $.ajax({
          type: "POST",
          url: '/sender.php',
          data: {
            imya: $('.form__input-name--foo').val(),
            tel: $('.form__input-phone--foo').val()
          },
          success: function () {
            thiser.after('<div class="uspeh1">Ваше сообщение отправлено.<br>Наш специалист свяжется с Вами в ближайшее время.</div>');
            // ym(92594057, 'reachGoal', 'zayvka');
            setTimeout(function () {
              $(".uspeh1").detach();
              $('.form__form').val('');
            }, 3000);
          }
        });
      }
      else {
        thiser.after('<div class="uspeh2">Поле "Ваш телефон" не заполнено.</div>');
        setTimeout(function () {
          $(".uspeh2").detach();
        }, 3000);
      }
    }
    else {
      thiser.after('<div class="uspeh2">Заполните Ваше имя.</div>');
      setTimeout(function () {
        $(".uspeh2").detach();
      }, 3000);
    }
  });

  $(".header__phone").click(function () {
    // ym(92594057, 'reachGoal', 'zvonok');
  });

});

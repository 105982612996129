import Splide from '@splidejs/splide';

window.document.addEventListener("DOMContentLoaded", () => {
  if (document.querySelector('.documents__splide')) {

    new Splide('.documents__splide', {
      direction: 'ltr',
      gap: '24px',
      perPage: 5,
      breakpoints: {
        930: {
          perPage: 4,
        },
        768: {
          gap: '12px',
        },
        576: {
          perPage: 3,
        },
        400: {
          perPage: 2,
        },
      },
    }).mount();

  }
})




import './components/sender';
import './components/sparks';
import './components/planet';
import './components/cards-paralax';
import './components/footer-bg';
import './components/burger';
import './components/partners-slider';
import './components/documents-slider';
import './components/popup';
import './components/scroll';
import './components/gototop';
import './components/inputmask';
import './components/flip-card';
import './components/accordion';
import './components/yametrika';
